import { shouldUseEventDesignDraft as checkForEventDesignDraft } from '@apps/guest/routes/GuestSite/GuestSite.utils';
import { withWindow } from '@shared/utils/withWindow';

export const shouldUseEventDesignDraft = () =>
  withWindow<boolean>(window => {
    const src = window.frameElement?.getAttribute('src');
    if (src) {
      const searchParams = `?${src.split('?')[1]}`;
      return checkForEventDesignDraft(searchParams);
    }
    return false;
  }, false);
