import { Box, ButtonV2, TextV2, Flex, IconV2 } from '@withjoy/joykit';
import React, { FC, useCallback, useState } from 'react';
import { styles } from './EventPreview.styles';
import { DateText } from '@apps/registry/guest/routes/GuestRegistry/components/CoupleHeader/CoupleHeader.styles';
import { PageNoteText } from '@apps/registry/guest/routes/GuestRegistry/components/CoupleHeader/PageNoteText';
import { useAdminRegistryState } from '../../../admin/state/context/AdminRegistry';
import { EventPhoto } from '@apps/registry/common/components/EventPreview/components/EventPhoto/EventPhoto';
import { EventTitle } from './components/EventTitle/EventTitle';
import { utcToZonedTime } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from '@apps/registry/guest/GuestRegistry.controller';
import { format } from 'date-fns';
import { useGetEventPreviewDataQuery } from '@graphql/generated';
import { useAdminRegistryTelemetry } from '@apps/registry/admin/AdminRegistry.telemetry';
import { ReactComponent as ChevronDown } from '@shared/joykit/packages/core/icons/svgs/16/chevron-down.svg';
import { ReactComponent as ChevronUp } from '@shared/joykit/packages/core/icons/svgs/16/chevron-up.svg';
import { useTranslation } from '@shared/core';
import { FontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';
import { ColorPaletteProvider } from '@apps/guest';

type Props = {
  eventHandle: string;
};

type IconCardProps = {
  icon: React.ReactNode;
  text: string;
  onClick(): void;
};

export const IconCard: FC<IconCardProps> = ({ icon, text, onClick }) => (
  <Flex as="button" onClick={onClick} __css={styles.iconCard}>
    <IconV2 size="xxl">{icon}</IconV2>
    <TextV2 typographyVariant="button2">{text}</TextV2>
  </Flex>
);

export const EventPreview: FC<Props> = ({ eventHandle }) => {
  const { t } = useTranslation('yourRegistry');
  const { showMore } = t('eventPreview');
  const [isExpanded, setIsExpanded] = useState(false);
  const { weddingName, pageNote, finalizedEventDate, website } = useAdminRegistryState();
  const { data: eventPreviewData } = useGetEventPreviewDataQuery({
    variables: { eventHandle },
    batchMode: 'fast'
  });
  const font = eventPreviewData?.eventByName?.eventDesign?.font?.fontFamily;
  const registryPage = eventPreviewData?.eventByName?.registryPage?.[0];
  const photo = registryPage?.photo?.url;
  const dateWithTimezoneFormatting = finalizedEventDate?.milliseconds
    ? utcToZonedTime(finalizedEventDate?.milliseconds as number, finalizedEventDate?.timezone || DEFAULT_TIMEZONE)
    : '';
  const formattedDate = dateWithTimezoneFormatting ? format(dateWithTimezoneFormatting, 'MMMM d, yyyy') : '';

  const telemetry = useAdminRegistryTelemetry();
  const { trackEventPreviewButtonInteraction } = telemetry || {};

  const onEditPageNoteTrack = useCallback(() => {
    trackEventPreviewButtonInteraction('Edit Note');
  }, [trackEventPreviewButtonInteraction]);

  const onUpdatePagePhotoTrack = useCallback(() => {
    trackEventPreviewButtonInteraction('Change Photo');
  }, [trackEventPreviewButtonInteraction]);

  const onDeletePagePhotoTrack = useCallback(() => {
    trackEventPreviewButtonInteraction('Delete Photo');
  }, [trackEventPreviewButtonInteraction]);

  const toggleView = useCallback(() => {
    trackEventPreviewButtonInteraction(isExpanded ? 'Show Less' : 'Show More');
    setIsExpanded(curr => !curr);
  }, [isExpanded, trackEventPreviewButtonInteraction]);

  return (
    <FontPackProvider eventDesign={eventPreviewData?.eventByName?.eventDesign}>
      <ColorPaletteProvider eventDesign={eventPreviewData?.eventByName?.eventDesign}>
        <Box __css={styles.root}>
          <Box __css={styles.content}>
            <EventTitle title={weddingName} font={font} isExpanded={isExpanded} />
            <Flex maxHeight={isExpanded ? '10000px' : '0'} __css={styles.collapsibleContent}>
              <Flex justifyContent="center" alignItems="center" width={{ _: '100%', md: '50%' }}>
                <Box width="100%">
                  {pageNote && (
                    <Box width="100%" marginBottom={6}>
                      {formattedDate && <DateText typographyVariant={{ _: 'hed3', md: 'hed5' }}>{formattedDate}</DateText>}
                    </Box>
                  )}
                  <PageNoteText isCustomButtonStylesEnabled={false} showEmptyCard noteText={pageNote} eventHandle={website} showEdit={true} onEditPageNote={onEditPageNoteTrack} />
                </Box>
              </Flex>
              <Flex justifyContent="center" alignItems="center" width={{ _: '100%', md: '50%' }}>
                <EventPhoto
                  onDeletePhoto={onDeletePagePhotoTrack}
                  onUpdatePhoto={onUpdatePagePhotoTrack}
                  photo={photo}
                  firebaseId={eventPreviewData?.eventByName?.firebaseId}
                  eventPageId={registryPage?.id}
                  showEdit
                  showEmptyCard
                />
              </Flex>
            </Flex>
          </Box>
          <ButtonV2
            onClick={toggleView}
            intent="neutral"
            fullWidth
            startIcon={isExpanded ? ChevronUp : ChevronDown}
            variant="ghost"
            borderTop="1px solid"
            borderColor="mono3"
            color="mono10"
            typographyVariant="label2"
          >
            {isExpanded ? '' : showMore()}
          </ButtonV2>
        </Box>
      </ColorPaletteProvider>
    </FontPackProvider>
  );
};
