import { DonationFundPlatformTypeEnum } from '@graphql/generated';
import { extractHostname } from '@shared/utils/extractHostname';
import { withWindow } from '@shared/utils/withWindow';
import { runIfFn } from '@shared/utils/functions';

const amazonRegex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]amazon+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
const storeList = [
  'amazon',
  'target',
  'zola',
  'macys',
  'bestbuy',
  'wayfair',
  'crateandbarrel',
  'potterybarn',
  'williams-sonoma',
  'ikea',
  'bedbathandbeyond',
  'anthropologie',
  'bloomingdales',
  'westelm',
  'sonos',
  'walmart',
  'ebay',
  'homedepot',
  'etsy',
  'honeyfund',
  'prezola',
  'weddingshop',
  'weddingpresentco',
  'tgt.gifts'
];

const storeListReadableNames = {
  amazon: 'Amazon',
  target: 'Target',
  zola: 'Zola',
  macys: `Macy's`,
  bestbuy: 'Best Buy',
  wayfair: 'Wayfair',
  crateandbarrel: 'Crate&Barrel',
  potterybarn: 'Pottery Barn',
  'williams-sonoma': 'Williams Sonoma',
  ikea: 'Ikea',
  bedbathandbeyond: 'Bed Bath & Beyond',
  anthropologie: 'Anthropologie',
  bloomingdales: `Bloomingdale's`,
  westelm: 'West Elm',
  sonos: 'Sonos',
  walmart: 'Walmart',
  ebay: 'Ebay',
  homedepot: 'Home Depot',
  etsy: 'Etsy',
  honeyfund: 'Honeyfund',
  prezola: 'Prezola',
  weddingshop: 'The Wedding Shop',
  weddingpresentco: 'The Wedding Present Company',
  'tgt.gifts': 'Target'
};

type StoreListReadableNames = keyof typeof storeListReadableNames;

export const isAmazonUri = (uri: Maybe<string>): boolean => {
  if (typeof uri !== 'string') {
    return false;
  }
  return amazonRegex.test(uri);
};

export const getStoreName = (uri: Maybe<string>, defaultText?: string): string => {
  if (typeof uri !== 'string') {
    return defaultText ? defaultText : 'Store';
  }
  const storeHostname = extractHostname(uri);
  const storeName = storeHostname.replace('.com', '');
  return storeList.includes(storeName) ? storeListReadableNames[storeName as StoreListReadableNames] : storeHostname;
};

const storeLogosSmall = {
  Amazon: 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/amazon.png',
  Target: 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/target.png',
  'Bed Bath & Beyond': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/bedbathbeyond.png',
  'Williams Sonoma': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/williamssonoma.png',
  'Pottery Barn': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/potterybarn.png',
  'West Elm': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/westelm.png',
  'Crate&Barrel': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/crateandbarrel.png',
  'Crate & Barrel': 'https://withjoy.com/assets/public/apps/registry/storeLogosSmall/crateandbarrel.png'
};

type StoreLogoKey = keyof typeof storeLogosSmall;

export const getStoreLogoByReadableStoreName = (storeName?: string | null) => {
  if (storeName && storeLogosSmall[storeName as StoreLogoKey]) {
    return storeLogosSmall[storeName as StoreLogoKey];
  }
  return null;
};

const redirectFromMobile = (global: Window, url: string, retailerUrl: string) => {
  // https://stackoverflow.com/questions/40362093/safari-window-open-doesnt-work
  // Only javascript that is allowed to open a new window in safari is JS directly attached to a user event.
  setTimeout(() => {
    global.location.href = url;
  }, 350);

  global.location.replace(retailerUrl);
};

export const openStoreRedirect = ({
  eventHandle,
  url,
  isDonation,
  platformType,
  amount,
  isMobileOrTablet,
  storeName,
  onAfterRedirect
}: {
  eventHandle: string;
  url: string;
  isDonation: boolean;
  platformType: Maybe<DonationFundPlatformTypeEnum>;
  /**
   * Only used for Venmo
   */
  amount?: string;
  isMobileOrTablet: boolean;
  storeName: string;
  onAfterRedirect?: () => void;
}) => {
  withWindow(global => {
    const pathNew = `${global.location.origin}/${eventHandle}/registry/retailer-redirect`;
    const encodedUrl = encodeURIComponent(url);

    // If venmo is registered, the user will see a dialog
    // asking if they want to open your app. If they agree, your
    // app will launch immediately and the timer won't fire.
    // If not installed, you'll get an ugly "Cannot Open Page"
    // dialogue and the base venmo url will open when the timer expires.
    // Unfortunately, no easy way to check if app is installed via JS.
    const venmoUrl = `${url}?txn=pay&note=Congrats!&amount=${amount}`;
    const retailerUrl = pathNew + `?url=${encodedUrl}&storeName=${storeName}`;
    const retailerUrlDonation = retailerUrl + '&donationFund=true';

    const urlToOpen = isDonation ? (platformType === DonationFundPlatformTypeEnum.venmo ? venmoUrl : retailerUrlDonation) : retailerUrl;

    if (isMobileOrTablet) {
      if (platformType === DonationFundPlatformTypeEnum.venmo) {
        redirectFromMobile(global, url, urlToOpen);
      }
      runIfFn(onAfterRedirect);
    } else {
      const newWin = global.open(urlToOpen, '_blank');
      const isRedirectBlocked = !newWin || newWin.closed || typeof newWin.closed == 'undefined';
      if (!isRedirectBlocked) {
        runIfFn(onAfterRedirect);
      }
    }
  });
};
