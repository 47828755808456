import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { GuestRegistryRouter } from './GuestRegistry.routes';
import { Route, Switch } from '@react-router';
import { useGuestRegistryController, GuestRegistryState } from './GuestRegistry.controller';
import { GuestRegistry as GuestRegistryList } from './routes/GuestRegistry';
import { GuestRegistryProvider as ReactProvider } from './state';
import { Purchase } from './routes/Purchase';
import { RegistryFontFaces } from '@apps/registry/common/util/fonts';
import { TelemetryProvider, useGuestRegistryTelemetry } from './GuestRegistry.telemetry';
import { RegistryProtected } from './routes/GuestRegistry/components/RegistryProtected';
import { ErrorSplash, getGiftWrapThemeListByEventType } from '@shared/components';
import { RegistryHidden } from './routes/GuestRegistry/components';
import { usePurchaseContextIdFromLocation } from '../common/utils/dropship';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import { StoredGiftReservationFieldsProvider } from './routes/GuestRegistry/components/StoredGiftReservationFieldsProvider';
import { PurchaseConfirmationModalProvider } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { NoSsr } from '@shared/components/NoSsr';
import { EventDesignPurpose, EventPageType, GuestEventDetailsByNameQuery, useGuestEventDetailsByNameQuery } from '@graphql/generated';
import { GuestRegistryHead } from './GuestRegistryHead';
import { useToggleIntercomLauncher } from '@shared/core/intercom';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventInfo } from '@shared/utils/eventInfo';
import { FontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';
import { DropshipPurchaseConfirmationV2 } from '../common/components/DropshipPurchaseConfirmation';
import { CartCheckoutSteps, ShoppingCart } from '../common/components/ShoppingCart';
import { ShoppingCartProvider } from '../common/components/ShoppingCart/state';
import { useRegistryHost } from '../common/utils/useRegistryHost';
import { ShoppingCartTelemetryProvider } from '../common/components/ShoppingCart/ShoppingCart.telemetry';
import { isInIframe } from '@shared/utils/isInIframe';
import { OnPreviewListenerUpdate } from './GuestRegistryPreviewListener';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { shouldUseEventDesignDraft } from './GuestRegistry.utils';
import { ColorPaletteProvider } from '@apps/guest';

interface GuestRegistryProviderProps {
  eventHandle: string;
}
interface GuestRegistryProps extends GuestRegistryProviderProps {
  handleOnPreviewUpdate: OnPreviewListenerUpdate;
}

type PurchaseRouteSwitchProps = Readonly<{
  eventHandle: string;
  registry: GuestRegistryState;
  isRegistryHidden: boolean;
}>;

export const PurchaseRouteSwitch = (props: PurchaseRouteSwitchProps) => {
  const purchaseContextId = usePurchaseContextIdFromLocation();

  if (purchaseContextId) {
    // `pcId` is present after user goes through stripe payment flow and is redirected
    return <DropshipPurchaseConfirmationV2 registries={props.registry.registry} />;
  } else {
    // Default to the existing purchase confirmation page.
    return (
      <Purchase
        isAdmin={props.registry.isAdmin}
        eventId={props.registry.eventId}
        eventHandle={props.eventHandle}
        registry={props.registry.registry}
        shippingAddress={props.registry.shippingAddress}
        isRegistryHidden={props.isRegistryHidden}
      />
    );
  }
};

const GuestRegistry: React.FC<GuestRegistryProps> = props => {
  const { registry, loading, routes, location, canAccessPage, eventId, error } = useGuestRegistryController(props.eventHandle);
  const { trackError } = useGuestRegistryTelemetry();
  const isMobileOrTablet = useIsMobileOrTablet();
  const { value: giftWrapOn } = useFeatureValue('registryGiftWrapExperimentEnabled');
  const registryGiftWrapExperimentEnabled = giftWrapOn === 'on';
  const { value: gwRegCartEnabled } = useFeatureValue('registryGiftWrapCartEnabled');
  const registryGiftWrapCartEnabled = gwRegCartEnabled === 'on';
  const { eventInfo } = useEventInfo();
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const eventTypeHasThemes = themes && themes.length > 0;
  // Verify the registry item is gift wrap experiment is enabled, and gift wrap for cart is enabled
  const isGiftWrapEnabled = registryGiftWrapExperimentEnabled && registryGiftWrapCartEnabled && eventTypeHasThemes;

  useToggleIntercomLauncher({ show: true, minScreenSize: 'mobile' });
  const host = useRegistryHost();

  let content: JSX.Element | null = null;

  if (loading || (registry && !host)) {
    content = <JoyLogoLoader loaderKey="guest-registry" />;
  } else if (!registry) {
    content = null;
  } else if (error) {
    trackError('guestRegistry', error);
    content = <ErrorSplash />;
  } else if (canAccessPage) {
    const checkoutStepsFlow = [
      CartCheckoutSteps.Cart,
      CartCheckoutSteps.Shipping,
      CartCheckoutSteps.Details,
      ...(isGiftWrapEnabled ? [CartCheckoutSteps.Message] : []),
      CartCheckoutSteps.Payment
    ];
    const sessionEmail = registry?.registry?.filter(registry => registry.id === eventId)[0]?.mySession?.email;
    content = (
      <PurchaseConfirmationModalProvider>
        <ColorPaletteProvider eventDesign={registry.eventDesign}>
          <ShoppingCartProvider coupleAddress={registry.shippingAddress} eventHandle={props.eventHandle} eventId={eventId} checkoutStepsFlow={checkoutStepsFlow}>
            <ShoppingCartTelemetryProvider context={{ eventId: registry.eventId }}>
              <TelemetryProvider context={{ eventId: registry.eventId, section: '' }}>
                <Switch location={location}>
                  <Route
                    exact={true}
                    path={routes.guestRegistry.path}
                    render={() => (
                      <GuestRegistryList eventHandle={props.eventHandle} eventId={eventId!} registryState={registry} handleOnPreviewUpdate={props.handleOnPreviewUpdate} />
                    )}
                  />
                  <Route
                    exact={true}
                    path={routes.purchase.path}
                    render={() => {
                      return <PurchaseRouteSwitch eventHandle={props.eventHandle} registry={registry} isRegistryHidden={!!registry.isRegistryPageDisabled} />;
                    }}
                  />

                  {/* TODO: extract out contribute route */}
                  <Route
                    exact={true}
                    path={routes.contribute.path}
                    render={() => (
                      <StoredGiftReservationFieldsProvider sessionEmail={sessionEmail}>
                        <Purchase
                          eventHandle={props.eventHandle}
                          registry={registry?.registry}
                          eventId={registry?.eventId}
                          isAdmin={registry.isAdmin}
                          shippingAddress={registry.shippingAddress}
                          isRegistryHidden={!!registry.isRegistryPageDisabled}
                          layoutOption={'donationFund'}
                        />
                      </StoredGiftReservationFieldsProvider>
                    )}
                  />
                  {/* For native apps */}
                  <Route
                    exact={true}
                    path={routes.shoppingCart.path}
                    render={() => (
                      <>
                        {!isMobileOrTablet ? (
                          <GuestRegistryList eventHandle={props.eventHandle} eventId={eventId!} registryState={registry} handleOnPreviewUpdate={props.handleOnPreviewUpdate} />
                        ) : (
                          <StoredGiftReservationFieldsProvider sessionEmail={sessionEmail}>
                            <ShoppingCart
                              eventId={eventId!}
                              isShoppingPage
                              registry={registry.registry}
                              orders={registry.orders}
                              shippingAddress={registry.shippingAddress}
                              registryCurrencyCode={registry.registryCurrencyCode}
                            />
                          </StoredGiftReservationFieldsProvider>
                        )}
                      </>
                    )}
                  />
                </Switch>
              </TelemetryProvider>
            </ShoppingCartTelemetryProvider>
          </ShoppingCartProvider>
        </ColorPaletteProvider>
      </PurchaseConfirmationModalProvider>
    );
  } else if (registry.isRegistryPageDisabled) {
    content = <RegistryHidden website={props.eventHandle} />;
  } else if (registry.isRegistryPagePasswordProtected) {
    content = <RegistryProtected eventId={eventId} eventHandle={props.eventHandle} />;
  }

  return <JoyKitThemeProvider>{content}</JoyKitThemeProvider>;
};

const isPreviewing = isInIframe();

export const GuestRegistryProvider: React.FC<GuestRegistryProviderProps> = props => {
  const { data } = useGuestEventDetailsByNameQuery({
    variables: {
      eventHandle: props.eventHandle,
      pageType: EventPageType.registry,
      eventDesignPurpose: shouldUseEventDesignDraft() ? EventDesignPurpose.draft : EventDesignPurpose.live
    },
    batchMode: 'fast',
    ssr: true,
    fetchPolicy: 'cache-first'
  });

  const [{ eventPreview }, setState] = useImmer<{ eventPreview: GuestEventDetailsByNameQuery | undefined }>(() => ({
    eventPreview: data
  }));

  // If previewing, use the clone. Else, use the original.
  const event = isPreviewing ? eventPreview?.eventByName : data?.eventByName;

  // Preview

  // Initialize preview data when query resolves
  useEffect(() => {
    if (isPreviewing) {
      setState(draft => {
        draft.eventPreview = data;
      });
    }
  }, [setState, data]);

  // Respond to event preview updates if previewing
  const handleOnPreviewUpdate = useEventCallback<OnPreviewListenerUpdate>(cb => {
    if (isPreviewing) {
      setState(draft => {
        const eventDraft = draft.eventPreview?.eventByName;
        if (eventDraft) {
          cb(eventDraft);
        }
      });
    }
  });

  return (
    <>
      {event && (
        <GuestRegistryHead
          eventDisplayName={event.info?.eventDisplayName}
          imageUrl={event.pageInfo?.page.photo?.url || event.photo?.url}
          handle={event.website}
          ownerFirstName={event.info.ownerFirstName}
          ownerLastName={event.info.ownerLastName}
          fianceeFirstName={event.info.fianceeFirstName}
          pageData={event.pages}
          eventType={event.info.eventType}
        />
      )}

      <NoSsr>
        <RegistryFontFaces />
        <GuestRegistryRouter eventHandle={props.eventHandle}>
          <ReactProvider>
            <FontPackProvider eventDesign={event?.eventDesign}>
              <GuestRegistry {...props} handleOnPreviewUpdate={handleOnPreviewUpdate} />
            </FontPackProvider>
          </ReactProvider>
        </GuestRegistryRouter>
      </NoSsr>
    </>
  );
};
