import { CustomItemDataType, DonationFundPaymentMethodEnum } from '@graphql/generated';
import { createMachine, assign, actions } from 'xstate';
import type { CheckoutMachine, CheckoutEvent, CheckoutContext } from './externalCheckout.types';
import { first } from 'lodash-es';
import { getDonationPaymentMethodFromPlatformType } from './utils';
const { choose } = actions;
export const createCheckoutMachine = (
  isAffiliate: boolean,
  isGroupGifting: boolean,
  donationAmount: number | undefined,
  giftWrapCashEnabled: boolean,
  giftWrapAffiliateEnabled: boolean,
  isGiftWrapPurchased: boolean,
  isEventInUS: boolean
): CheckoutMachine => {
  return createMachine(
    {
      schema: {
        context: {} as CheckoutContext,
        events: {} as CheckoutEvent
      },
      tsTypes: {} as import('./externalCheckout.machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        flowVariant: 'full',
        giftStatus: 'new',
        registryItemExists: false,
        registryItemName: undefined,
        registryItemPrice: undefined,
        platformType: undefined,
        dataType: undefined,
        isAffiliate,
        isGiftWrapPurchased,
        isGroupGifting,
        registryItemId: undefined,

        // form fields
        formValues: {
          amount: isGroupGifting && donationAmount ? donationAmount : undefined,
          quantity: undefined,
          name: undefined,
          email: undefined,
          note: undefined,
          paymentMethod: undefined,
          savedGiftWrapMessage: ''
        }
      },
      id: 'checkoutFlow',
      initial: 'setup',
      states: {
        // Redirect to correct starting state based on gift status
        setup: {
          initial: 'waitInitialization',
          states: {
            waitInitialization: {
              on: {
                INIT: {
                  actions: 'applyConfig',
                  target: 'goToInitialState'
                }
              }
            },

            goToInitialState: {
              always: [
                { target: '#registryItemNotFound', cond: 'shouldStartOffAtRegistryItemNotFound' },
                // Start at externalCheckout.instructions if a charity gift was reserved and journey began from shopping cart
                { target: '#checkout.paperCheckout.instructionsGiftWrap', cond: 'shouldStartOffAtPaperInstructionsForGiftWrap' },
                { target: '#checkout.externalCheckout.instructionsGiftWrap', cond: 'shouldStartOffAtExternalInstructionsForGiftWrap' },
                { target: '#checkout.externalCheckout.externalCheckoutCopyAddress', cond: 'shouldStartFromCopyAddress' },
                { target: '#checkout.externalCheckout.instructions', cond: 'shouldStartOffAtInstructionsForCharity' },
                // Only start at paymentMethod if gift was reserved and journey began from shopping cart
                { target: '#paymentMethod', cond: 'shouldStartOffAtPaymentMethodState' },
                { target: '#checkout', cond: 'shouldStartOffAtCheckoutState' },
                { target: '#gifterDetails', cond: ({ isGroupGifting, formValues }) => isGroupGifting && !!formValues.amount },
                { target: '#giftPdp', cond: ({ isGroupGifting, isAffiliate, formValues }) => isAffiliate || (isGroupGifting && !formValues.amount) },
                // Default state
                { target: '#giftAmount' }
              ]
            }
          }
        },
        giftAmount: {
          id: 'giftAmount',
          on: {
            SUBMIT_GIFT_AMOUNT_FORM: {
              actions: 'handleSubmitGiftAmount',
              target: 'gifterDetails'
            }
          }
        },
        giftWrap: {
          id: 'giftWrap',
          initial: 'init',
          states: {
            init: {
              always: [
                { target: '#checkout.externalCheckout', cond: () => (!giftWrapAffiliateEnabled || !isEventInUS) && isAffiliate },
                {
                  target: '#checkout',
                  cond: ({ dataType }) => !isEventInUS || !giftWrapCashEnabled || dataType !== CustomItemDataType.cash
                },
                { target: '#giftWrap.offer' }
              ]
            },
            offer: {
              on: {
                BACK: { target: '#paymentMethod', cond: 'canEditPaymentMethod' },
                SUBMIT_GIFT_WRAP: [
                  {
                    target: '#checkout.externalCheckout',
                    actions: ['handleSubmitGiftNote', 'reserveAndAddToCart'],
                    cond: ({ isAffiliate }) => isAffiliate && giftWrapAffiliateEnabled
                  },
                  {
                    target: '#checkout',
                    actions: ['handleSubmitGiftNote', 'reserveAndAddToCart']
                  }
                ],
                SUBMIT_GIFT_NOTE: [
                  {
                    target: '#checkout.externalCheckout',
                    actions: ['handleSubmitGiftNote', 'reserveAndAddToCart'],
                    cond: ({ isAffiliate }) => isAffiliate && giftWrapAffiliateEnabled
                  },
                  {
                    target: '#checkout',
                    actions: ['handleSubmitGiftNote', 'reserveAndAddToCart']
                  }
                ],
                SKIP_GIFT_WRAP: {
                  target: '#giftWrapNote',
                  actions: ['saveGiftWrapMessage']
                }
              }
            }
          }
        },
        giftWrapNote: {
          id: 'giftWrapNote',
          on: {
            BACK: { target: 'giftWrap' },
            SUBMIT_GIFT_NOTE: [
              {
                target: '#checkout.externalCheckout',
                actions: ['handleSubmitGiftNote', 'reserveAndAddToCart'],
                cond: ({ isAffiliate }) => isAffiliate && giftWrapAffiliateEnabled
              },
              {
                target: 'checkout',
                actions: ['handleSubmitGiftNote', 'reserveAndAddToCart']
              }
            ],
            UPGRADE_GIFT_CARD: {
              target: 'giftWrap'
            }
          }
        },
        giftPdp: {
          id: 'giftPdp',
          on: {
            SUBMIT_GIFT_PDP_FORM: {
              actions: 'handleSubmitGiftPdp',
              target: 'gifterDetails'
            },
            UPDATE_DONATION_AMOUNT: {
              actions: 'handleUpdateDonationAmount',
              target: 'gifterDetails'
            }
          }
        },
        gifterDetails: {
          id: 'gifterDetails',
          onDone: [
            {
              cond: ({ flowVariant, isAffiliate }) => flowVariant === 'full' && isEventInUS && isAffiliate && giftWrapAffiliateEnabled,
              target: 'giftWrap.offer',
              internal: true
            },
            {
              cond: ({ flowVariant, platformType, isAffiliate }) => flowVariant === 'full' && (platformType === DonationFundPaymentMethodEnum.other || isAffiliate),
              target: 'checkout.externalCheckout.instructions',
              internal: true
            },
            {
              cond: ({ flowVariant }) => flowVariant === 'full',
              target: 'paymentMethod',
              internal: true
            },
            {
              cond: ({ flowVariant, isAffiliate }) => flowVariant === 'deferred' && isAffiliate,
              target: 'checkout.externalCheckout.confirmAndNotify',
              internal: true
            }
          ],
          initial: 'collection',
          states: {
            collection: {
              on: {
                BACK: [
                  { cond: ({ isGroupGifting }) => isGroupGifting, target: undefined },
                  { cond: 'shouldUseAffiliateCheckout', target: '#giftPdp' },
                  {
                    cond: 'isNewGift',
                    target: '#giftAmount'
                  }
                ],
                SUBMIT_GUEST_DETAILS: [
                  {
                    actions: ['handleSubmitGuestDetails'],
                    target: 'submitted',
                    cond: () => giftWrapAffiliateEnabled
                  },
                  {
                    actions: ['handleSubmitGuestDetails', 'reserveAndAddToCart'],
                    target: 'submitted'
                  }
                ]
              }
            },
            submitted: {
              entry: choose([
                {
                  cond: (context: CheckoutContext) => context.flowVariant === 'deferred',
                  actions: []
                }
              ]),
              type: 'final'
            }
          }
        },
        paymentMethod: {
          id: 'paymentMethod',
          on: {
            BACK: {
              target: '#gifterDetails',
              cond: 'canEditGuestDetails'
            },
            SELECT_PAYMENT_METHOD: [
              {
                actions: 'handleSelectPaymentMethod',
                target: 'giftWrap',
                cond: () => giftWrapCashEnabled
              },
              {
                actions: ['handleSelectPaymentMethod', 'reserveAndAddToCart'],
                target: 'checkout'
              }
            ]
          }
        },
        checkout: {
          id: 'checkout',
          initial: 'init',
          states: {
            init: {
              always: [
                { target: '#checkout.paperCheckout', cond: 'shouldUsePaperCheckoutFlow' },
                { target: '#checkout.externalCheckout', cond: 'shouldUseExternalCheckoutFlow' },
                { target: '#checkout.creditCardCheckout', cond: 'shouldUseCreditCardCheckoutFlow' }
              ]
            },
            creditCardCheckout: {
              id: 'creditCardCheckout',
              initial: 'initCheckout',
              states: {
                initCheckout: {
                  id: 'initCheckout',
                  entry: ['createCreditCardPurchase']
                }
              }
            },
            paperCheckout: {
              id: 'paperCheckout',
              initial: 'init',
              states: {
                init: {
                  always: [{ target: '#checkout.paperCheckout.instructionsGiftWrap', cond: 'hasGiftWrapNote' }, { target: '#checkout.paperCheckout.instructions' }]
                },
                instructions: {
                  on: {
                    BACK: { target: '#paymentMethod', cond: 'canEditPaymentMethod' },
                    NEXT: { target: 'confirmAndNotify' }
                  }
                },
                instructionsGiftWrap: {
                  id: 'instructionsGiftWrap',
                  initial: 'idle',
                  states: {
                    idle: {
                      id: 'idle',
                      on: {
                        NEXT: [
                          { target: 'loading', actions: ['createPurchaseContext'], cond: ({ isGiftWrapPurchased }) => !isGiftWrapPurchased },
                          { target: '#checkout.paperCheckout.confirmAndNotify' }
                        ],
                        BACK: { target: '#giftWrap', cond: ({ isGiftWrapPurchased }) => !isGiftWrapPurchased }
                      }
                    },
                    loading: {
                      id: 'loading'
                    }
                  }
                },
                confirmAndNotify: {
                  on: {
                    BACK: [{ target: 'instructionsGiftWrap', cond: ({ isGiftWrapPurchased }) => isGiftWrapPurchased }, { target: 'instructions' }],
                    NEXT: { actions: 'markGiftAsPurchased', target: '#confirmedGift' },
                    INITIATE_CANCEL_PROCESS: { target: '#cancelGift' }
                  }
                }
              }
            },
            externalCheckout: {
              id: 'externalCheckout',
              initial: 'init',
              states: {
                init: {
                  always: [{ target: '#checkout.externalCheckout.instructionsGiftWrap', cond: 'hasGiftWrapNote' }, { target: '#checkout.externalCheckout.instructions' }]
                },
                instructionsGiftWrap: {
                  id: 'instructionsGiftWrap',
                  initial: 'idle',
                  states: {
                    idle: {
                      id: 'idle',
                      on: {
                        NEXT: [
                          { target: 'loading', actions: ['createPurchaseContext'], cond: ({ isGiftWrapPurchased }) => !isGiftWrapPurchased },
                          { target: '#checkout.externalCheckout.externalCheckoutCopyAddress', cond: 'shouldUseAffiliateCheckout' },
                          { target: '#checkout.externalCheckout.review' }
                        ],
                        BACK: { target: '#giftWrap', cond: ({ isGiftWrapPurchased }) => !isGiftWrapPurchased }
                      }
                    },
                    loading: {
                      id: 'loading'
                    }
                  }
                },
                instructions: {
                  id: 'instructions',
                  on: {
                    BACK: [
                      {
                        target: '#gifterDetails',
                        cond: ({ flowVariant, platformType, isAffiliate }) => (flowVariant === 'full' && platformType === DonationFundPaymentMethodEnum.other) || isAffiliate
                      },
                      { target: '#paymentMethod', cond: 'canEditPaymentMethod' }
                    ],
                    NEXT: [{ target: 'externalCheckoutCopyAddress', cond: 'shouldUseAffiliateCheckout' }, { target: 'review' }]
                  }
                },
                externalCheckoutCopyAddress: {
                  id: 'externalCheckoutCopyAddress',
                  on: {
                    BACK: [
                      {
                        cond: ({ isGiftWrapPurchased }) => isGiftWrapPurchased,
                        target: 'instructionsGiftWrap'
                      },
                      { target: 'instructions' }
                    ],
                    NEXT: { target: 'review' }
                  }
                },
                review: {
                  on: {
                    BACK: [
                      {
                        cond: ({ platformType }) => platformType === DonationFundPaymentMethodEnum.other,
                        target: '#gifterDetails'
                      },
                      {
                        cond: ({ isGiftWrapPurchased }) => isGiftWrapPurchased && isAffiliate,
                        target: 'externalCheckoutCopyAddress'
                      },
                      {
                        cond: ({ platformType, isGiftWrapPurchased }) => isGiftWrapPurchased && platformType !== DonationFundPaymentMethodEnum.other,
                        target: 'instructionsGiftWrap'
                      },
                      {
                        cond: ({ platformType }) => platformType !== DonationFundPaymentMethodEnum.other,
                        target: 'instructions'
                      }
                    ],
                    NEXT: { target: 'confirmAndNotify' },
                    INITIATE_CANCEL_PROCESS: { target: '#cancelGift' }
                  }
                },
                confirmAndNotify: {
                  on: {
                    BACK: undefined,
                    CLOSE_DIALOG: undefined,
                    NEXT: [
                      { cond: 'shouldUseAffiliateCheckout', target: 'provideTrackingDetails', actions: 'markGiftAsPurchased' },
                      { target: '#confirmedGift', actions: 'markGiftAsPurchased' }
                    ],
                    INITIATE_CANCEL_PROCESS: { target: '#cancelGift' },
                    GET_HELP: { target: 'getHelp' }
                  }
                },
                provideTrackingDetails: {
                  id: 'provideTrackingDetails',
                  on: {
                    BACK: undefined,
                    CLOSE_DIALOG: undefined,
                    SUBMIT_TRACKING_DETAILS_FORM: { target: '#confirmedGift', actions: ['handleSubmitTrackingDetails', 'annotateRegistryOrder'] },
                    ADD_TRACKING_LATER: { target: undefined, actions: 'onCloseDialog' },
                    GET_HELP: { target: 'getHelp' }
                  }
                },
                getHelp: {
                  on: {
                    BACK: undefined,
                    CLOSE_DIALOG: undefined,
                    NEXT: { target: 'confirmAndNotify' }
                  }
                }
              }
            }
          }
        },

        // 3 entrypoints into cancelGift state
        //  1. paperCheckout.confirmAndNotify state
        //  2. externalCheckout.confirmAndNotify state
        //  3. externalCheckout.review
        cancelGift: {
          id: 'cancelGift',
          initial: 'intent',
          states: {
            intent: {
              always: [{ target: '#cancelGift.warning', cond: 'shouldGoToCancelGiftWarning' }],
              on: {
                BACK: { target: '#checkout.externalCheckout.confirmAndNotify', cond: 'shouldUseExternalCheckoutFlow' },
                SEND_GIFT_LATER: { target: undefined, actions: 'onCloseDialog' },
                // TODO: Add cancel reservation
                NO_INTENT_TO_SEND: { target: 'warning' }
              }
            },
            warning: {
              on: {
                DISPROVE_CANCEL: { target: 'cancellationAborted' },
                CONFIRM_CANCEL: { actions: 'removeFromCart', target: 'cancellationConfirmed' }
              }
            },
            cancellationAborted: {
              // Redirect to correct purchase confirmation screen
              always: [
                // If gift was marked as reserved
                { target: '#checkout.externalCheckout.review', cond: 'shouldGoToExternalCheckoutReviewStateAfterGiftCancelAbort' },

                // If gift was marked as reserved
                { target: '#checkout.paperCheckout.confirmAndNotify', cond: 'shouldUsePaperCheckoutFlow' },
                { target: '#checkout.externalCheckout.confirmAndNotify', cond: 'shouldUseExternalCheckoutFlow' }
              ]
            },
            cancellationConfirmed: {
              type: 'final',
              on: {
                // By marking this event as  `undefined`, this means it's not supported
                BACK: undefined,
                CLOSE_DIALOG: undefined,
                NEXT: {
                  actions: 'onCloseDialog'
                }
              }
            }
          }
        },
        confirmedGift: {
          id: 'confirmedGift',
          // TODO: check final type that prevents onCloseDialog to run
          // type: 'final',
          on: {
            BACK: undefined,
            CLOSE_DIALOG: undefined,
            NEXT: {
              actions: 'onCloseDialog'
            }
          }
        },
        alreadyReserved: {
          type: 'final'
        },
        alreadyPurchased: {
          type: 'final'
        },
        registryItemNotFound: {
          id: 'registryItemNotFound',
          type: 'final'
        }
      },
      on: {
        CLOSE_DIALOG: {
          // Named action -- intended to be implemented when using `useMachine` or `useInterpreter`
          actions: 'onCloseDialog'
        }
      }
    },
    {
      actions: {
        applyConfig: assign({
          giftStatus: (ctx, evt) => {
            if (evt.data.existingOrder) {
              return evt.data.existingOrder.isPurchased ? 'purchased' : 'reserved';
            }
            return 'new';
          },
          registryItemExists: (ctx, evt) => {
            return !!evt.data.registryItem;
          },
          registryItemId: (ctx, evt) => {
            return evt.data.registryItem?.id;
          },
          registryItemName: (ctx, evt) => {
            return evt.data.registryItem?.productData.title;
          },
          registryItemPrice: (ctx, evt) => {
            return evt.data.registryItem?.productData.price?.valueInMinorUnits || 0;
          },
          platformType: (ctx, evt) => {
            return evt.data.existingOrder?.platformType || getDonationPaymentMethodFromPlatformType(evt.data.registryItem?.donationFund?.platform?.type) || undefined;
          },
          dataType: (ctx, evt) => {
            return evt.data.registryItem?.donationFund?.fundType;
          },
          isAffiliate: ctx => {
            return ctx.isAffiliate;
          },
          formValues: (ctx, evt) => {
            const order = first(evt.data.existingOrder?.lineItems) as { quantity: number };
            return {
              ...ctx.formValues,
              email: evt.data?.existingOrder?.email,
              name: evt.data?.existingOrder?.name,
              note: evt.data?.existingOrder?.note ?? undefined,
              quantity: order?.quantity,
              paymentMethod: evt.data?.existingOrder?.platformType || ctx.formValues.paymentMethod,
              ...evt.data
            };
          }
        }),
        handleSubmitGuestDetails: assign({
          formValues: (ctx, evt) => {
            if (evt.type === 'SUBMIT_GUEST_DETAILS') {
              return {
                ...ctx.formValues,
                ...evt.data
              };
            }
            return ctx.formValues;
          }
        }),
        handleSubmitGiftAmount: assign({
          flowVariant: (ctx, evt) => evt.data.flowVariant,
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              amount: evt.data.amount
            };
          }
        }),
        handleSelectPaymentMethod: assign({
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              paymentMethod: evt.data.paymentMethod
            };
          }
        }),
        handleSubmitGiftPdp: assign({
          formValues: (ctx, evt) => {
            return {
              ...(ctx.formValues as Object),
              quantity: evt.data.quantity
            };
          },
          flowVariant: (ctx, evt) => evt.data.flowVariant,
          isGroupGifting: false,
          isAffiliate: true
        }),
        handleUpdateDonationAmount: assign({
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              amount: evt.data.donationAmount
            };
          }
        }),
        handleSubmitTrackingDetails: assign({
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              orderNumber: evt.data.orderNumber,
              trackingNumber: evt.data.trackingNumber
            };
          }
        }),
        handleSubmitGiftNote: assign({
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              note: evt.data.note || '',
              savedGiftWrapMessage: 'savedGiftWrapMessage' in evt.data ? evt.data.savedGiftWrapMessage : ''
            };
          }
        }),
        saveGiftWrapMessage: assign({
          formValues: (ctx, evt) => {
            return {
              ...ctx.formValues,
              savedGiftWrapMessage: evt.data.message || ''
            };
          }
        })
      },
      guards: {
        shouldStartOffAtExternalInstructionsForGiftWrap: ctx => {
          return (giftWrapAffiliateEnabled || (giftWrapCashEnabled && ctx.formValues.paymentMethod !== DonationFundPaymentMethodEnum.cashOrCheck)) && ctx.isGiftWrapPurchased;
        },
        shouldStartOffAtPaperInstructionsForGiftWrap: ctx => {
          return giftWrapCashEnabled && ctx.isGiftWrapPurchased && ctx.formValues.paymentMethod === DonationFundPaymentMethodEnum.cashOrCheck;
        },
        shouldGoToCancelGiftWarning: ctx => {
          return ctx.formValues.paymentMethod === DonationFundPaymentMethodEnum.cashOrCheck || ctx.formValues.paymentMethod === DonationFundPaymentMethodEnum.other;
        },
        shouldGoToExternalCheckoutReviewStateAfterGiftCancelAbort: ctx => {
          return ctx.formValues.paymentMethod !== DonationFundPaymentMethodEnum.cashOrCheck;
        },
        shouldUsePaperCheckoutFlow: ctx => {
          return ctx.formValues.paymentMethod === DonationFundPaymentMethodEnum.cashOrCheck;
        },
        shouldUseExternalCheckoutFlow: ctx => {
          const { paymentMethod } = ctx.formValues;
          return !!paymentMethod && paymentMethod !== DonationFundPaymentMethodEnum.cashOrCheck && paymentMethod !== DonationFundPaymentMethodEnum.joyCredit;
        },
        shouldUseCreditCardCheckoutFlow: ctx => {
          const { paymentMethod } = ctx.formValues;
          return !!paymentMethod && paymentMethod === DonationFundPaymentMethodEnum.joyCredit;
        },
        shouldStartOffAtCheckoutState: ctx => {
          return ctx.giftStatus === 'reserved' && !!ctx.formValues.paymentMethod;
        },
        shouldStartOffAtPaymentMethodState: ctx => {
          return ctx.giftStatus === 'reserved' && !ctx.formValues.paymentMethod;
        },
        shouldStartOffAtInstructionsForCharity: ctx => {
          return ctx.giftStatus === 'reserved' && ctx.platformType === DonationFundPaymentMethodEnum.other;
        },
        shouldStartOffAtRegistryItemNotFound: ctx => {
          return !ctx.registryItemExists;
        },
        canEditGuestDetails: ctx => {
          return ctx.giftStatus === 'new';
        },
        canEditPaymentMethod: ctx => {
          return (ctx.giftStatus === 'new' || (ctx.giftStatus === 'reserved' && !ctx.formValues.paymentMethod)) && ctx.platformType !== DonationFundPaymentMethodEnum.other;
        },
        isNewGift: ctx => {
          return ctx.giftStatus === 'new';
        },
        shouldUseAffiliateCheckout: ctx => {
          return ctx.isAffiliate;
        },
        shouldStartFromCopyAddress: ctx => {
          return ctx.giftStatus === 'reserved' && ctx.isAffiliate;
        },
        hasGiftWrapNote: ctx => {
          if (!ctx.formValues.note || (!giftWrapAffiliateEnabled && !giftWrapCashEnabled)) {
            return false;
          }

          let value = false;

          try {
            const json = JSON.parse(ctx.formValues.note || '');

            if (json.message) {
              value = true;
            }
          } finally {
            return value;
          }
        }
      }
    }
  );
};
