import React, { useMemo } from 'react';
import { StyledButton } from './DashboardButton.styles';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useGuestRegistryTelemetry } from '../../GuestRegistry.telemetry';
import { ReactComponent as GiftIcon } from '@assets/icons/icon-gift.svg';
import { Box } from '@withjoy/joykit';
import { useEventTypeConfig } from '@shared/utils/eventType';
import { useColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';
import { getButtonPropsBasedOnBackground } from '@apps/guest/packages/layout-engine/layouts/layout.utils';

interface Props {
  website: string;
}

export const DashboardButton: React.FC<Props> = ({ website }) => {
  const { registryDashboardButton } = useGuestRegistryTelemetry();

  const { getCoupleHeaderTranslations } = useRegistryGuestTranslations();
  const { dashboardBtn } = getCoupleHeaderTranslations();
  const { config } = useEventTypeConfig();
  const { backgroundColor } = useColorPaletteProvider();
  const customButtonProps = useMemo(() => (backgroundColor ? { ...getButtonPropsBasedOnBackground(backgroundColor) } : {}), [backgroundColor]);
  return (
    <StyledButton
      data-testid="dashboard-button"
      width={['100%', '200px']}
      onClick={() => {
        registryDashboardButton(website);
        const url = window.location.origin + `/${website}${config?.registryAdminManageLink}`;
        window.open(url, '_blank');
      }}
      backgroundColor="rgba(0, 0, 0, 0.8)"
      color="white"
      {...customButtonProps}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box marginRight={3} display="flex" justifyContent="center" alignItems="center">
          <GiftIcon />
        </Box>
        {dashboardBtn}
      </Box>
    </StyledButton>
  );
};
