import { styled, Flex, StyleSystemProps } from '@withjoy/joykit';
import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledFlex = styled(Flex)<{ linkColor?: string }>`
  > a {
    padding: 0 1rem;
    color: ${props => props.linkColor || props.theme.colors.mono10};
  }
`;

export const ParticleJoyLogoWithPadding = styled(ParticleJoyLogo)`
  padding: ${pxToRem(3)} 3rem 0 3rem;
  & > div {
    & > svg {
      width: ${pxToRem(40)};
    }
  }
`;

export const getLinkStyles = (linkColor: string, linkHoverColor: string): StyleSystemProps => ({
  typographyVariant: 'label3',
  textDecoration: 'none',
  fontSize: 'inherit',
  color: linkColor,
  letterSpacing: '-0.03em',
  fontWeight: 'normal',
  _visited: {
    color: linkColor
  },
  _active: {
    color: linkColor
  },
  _hover: {
    color: linkHoverColor
  }
});
