import React from 'react';
import { TextV2, SpacingStack, Box, Flex } from '@withjoy/joykit';
import {
  ProductSubTitleWrapper,
  ProductTitleWrapper,
  StyledMustHaveWrapper,
  StyledProductPhoto,
  StyledProductPhotoWrapper,
  StyledProductTile,
  StyledTileWrapper,
  styles
} from './ProductTile.styles';
import { useTranslation } from '@shared/core';
import {
  CookedProduct,
  productStatus,
  ItemWithPrice,
  isProductFullyPurchased,
  productStatusDynamic,
  isCustomDonationFundItem
} from '@apps/registry/common/selectors/ProductListSelector';
import { addRendition } from '@shared/utils/photoRendition';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { RegistryItemProgressTrackerV2 } from '@apps/registry/admin/components';
import { pxToRem } from '@withjoy/joykit/theme';
import { ProductStatusFlag, ProductStatusFlags } from './ProductStatusFlag';
import { useIsMobileOrTablet, useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { HeartFilled } from '@withjoy/joykit/icons';
import { useCurrencyFormatter } from '@shared/utils/currency';

type Props = {
  product: ItemWithPrice;
  handleClickProduct?: (product: CookedProduct, isReservedByYou?: boolean, isPurchasedByYou?: boolean) => void;
  reservedOrderList?: Maybe<string>[];
  purchasedOrderList?: Maybe<string>[];
};

const ProductSubtitle = ({ product }: { product: ItemWithPrice }) => {
  const { t } = useTranslation('guestRegistry');
  const { charity, cashFund } = t('productTile');

  const getProductSubtitle = () => {
    if (product.brand) {
      return product.brand;
    }
    if (product.externallyOwned || product.registry.isTransferredType) {
      return product.storeName || '';
    }
    if (product.custom?.type === 'charity') {
      return charity();
    }
    if (product.custom?.type === 'cash') {
      return cashFund();
    }
    return '';
  };

  const productSubtitle = getProductSubtitle();

  return (
    <Box height={pxToRem(18)}>
      <TextV2 typographyVariant="label1" color="mono10" lineHeight={pxToRem(18)}>
        {productSubtitle}
      </TextV2>
    </Box>
  );
};

export const ProductTile = ({ product, handleClickProduct, reservedOrderList, purchasedOrderList }: Props) => {
  const { t } = useTranslation('guestRegistry');
  const { t: translations } = useTranslation('catalogRegistry');
  const { message } = translations('salesPrice');
  const { orders } = useGuestRegistryState();
  const isMobileOrTablet = useIsMobileOrTablet();
  const isMobile = useIsMobileScreen();

  const { formatCurrency } = useCurrencyFormatter();
  const { ourMostWanted } = t('productTile');

  const isReservedByYou = reservedOrderList?.some(item => product.id === item);
  const isPurchasedByYou = purchasedOrderList?.some(item => product.id === item);

  const productStatusText = productStatus(product, orders, isReservedByYou, isPurchasedByYou);
  const productStatusLabel = productStatusText ? t('productTile')[productStatusText]() : '';

  const productStatusDynamicText = productStatusDynamic(product, orders, isReservedByYou, isPurchasedByYou, !productStatusText);
  const productStatusDynamicLabel = productStatusDynamicText ? t('productTile')[productStatusDynamicText]() : '';

  const isPurchased = productStatusText === ProductStatusFlags.Purchased;
  const isCompleted = isProductFullyPurchased(product) || isPurchased;
  const fullPrice = formatCurrency({
    priceFloatingPointDecimalString: product?.fullPrice || '',
    priceCurrencyCode: product?.fullPriceCurrencyCode,
    formatForm: 'short'
  });

  return (
    <StyledTileWrapper
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      width="100%"
      cursor={isPurchased ? 'default' : 'pointer'}
      onClick={() => {
        if (!isPurchased) {
          handleClickProduct?.(product, isReservedByYou, isPurchasedByYou);
        }
      }}
      canClick={!isPurchased}
      paddingBottom={isMobileOrTablet ? 7 : 0}
      __css={!isMobileOrTablet && !isCompleted ? styles.productTileWrapper : undefined}
    >
      <StyledProductTile canClick={!isPurchased} width="100%" position="relative">
        <Flex justifyContent="center">
          {product.mustHave && (
            <StyledMustHaveWrapper __css={styles.mustHaveWrapper}>
              <HeartFilled color="#333" size={13} />
              <TextV2 fontSize={pxToRem(13)} paddingLeft="8px" lineHeight="18px" fontWeight={600} color="mono14">
                {ourMostWanted()}
              </TextV2>
            </StyledMustHaveWrapper>
          )}
        </Flex>
        <Flex justifyContent="center" height={pxToRem(21)}>
          {productStatusDynamicText && productStatusDynamicLabel && (
            <TextV2 typographyVariant="hed1" color="mono10" textTransform="uppercase">
              {productStatusDynamicLabel}
            </TextV2>
          )}
        </Flex>

        <StyledProductPhotoWrapper __css={styles.productPhotoWrapper}>
          <StyledProductPhoto $isCompleted={isCompleted} photoUrl={addRendition({ url: product.image, renditionSize: 'medium', isProduct: true })} __css={styles.productPhoto} />
        </StyledProductPhotoWrapper>
        <Box paddingX={[5, 0]} textAlign="center">
          <SpacingStack stack="vertical" spacing={1} width="100%" alignItems="center" paddingTop={5}>
            <ProductSubTitleWrapper textAlign="center">
              <ProductSubtitle product={product} />
            </ProductSubTitleWrapper>
            <ProductTitleWrapper __css={styles.productTitle}>
              <TextV2
                typographyVariant="hed2"
                color={isCompleted ? 'mono10' : 'mono14'}
                textAlign="center"
                lineHeight={isMobile ? pxToRem(20) : pxToRem(22)}
                fontSize={[14, 17]}
                __css={styles.productTitleText}
              >
                {product.title}
              </TextV2>
            </ProductTitleWrapper>
            {!isCustomDonationFundItem(product) && (
              <Flex gap={2}>
                <TextV2 typographyVariant="label6" textDecoration={!product.isFullPrice ? 'line-through' : ''} color={isCompleted ? 'mono6' : 'mono10'} lineHeight={pxToRem(18)}>
                  {!product.isFullPrice ? fullPrice : product.wholeNumPrice}
                </TextV2>
                {!product.isFullPrice && (
                  <TextV2 typographyVariant="label6" color="#D80B0B" lineHeight={pxToRem(18)}>
                    {message()} {product.wholeNumPrice}
                  </TextV2>
                )}
              </Flex>
            )}
          </SpacingStack>
          <RegistryItemProgressTrackerV2 product={product} orders={orders} />
        </Box>
      </StyledProductTile>
      {productStatusText && productStatusLabel && (
        <Box marginTop={5}>
          <ProductStatusFlag status={productStatusText} label={productStatusLabel} />
        </Box>
      )}
    </StyledTileWrapper>
  );
};
