import React, { useMemo } from 'react';
import { LinkV1, LinkV2, useTheme } from '@withjoy/joykit';
import { StyledFlex, ParticleJoyLogoWithPadding, getLinkStyles } from './RegistryFooter.styles';
import { useTranslation } from '@shared/core';
import { useColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';
import { getFooterLinkColor, getHoverColor } from '@apps/guest/packages/layout-engine/layouts/layout.utils';

const FOOTER_HELP_URL = 'http://help.withjoy.com/guest-help/?utm_source=guest-site-registry&utm_medium=viral';
const FOOTER_ABOUT_URL = 'https://withjoy.com/?utm_source=guest-site-registry&utm_medium=viral';

export const RegistryFooter: React.FC = () => {
  const { t2 } = useTranslation('guestRegistry');
  const { help, about } = t2('registryFooter');
  const { colors } = useTheme();
  const { backgroundColor } = useColorPaletteProvider();
  const { linkColor, linkHoverColor } = useMemo(() => {
    const linkColor = backgroundColor ? getFooterLinkColor(backgroundColor) : colors.mono10;
    const linkHoverColor = backgroundColor ? getHoverColor(linkColor) : colors.mono14;
    return { linkColor, linkHoverColor };
  }, [backgroundColor, colors.mono10, colors.mono14]);

  return (
    <StyledFlex justifyContent="center" alignItems="center" marginY={[120]} linkColor={linkColor}>
      <LinkV2 href={FOOTER_HELP_URL} target="__blank" {...getLinkStyles(linkColor, linkHoverColor)}>
        {help}
      </LinkV2>
      <LinkV1 href={FOOTER_ABOUT_URL} underline="none" target="__blank">
        <ParticleJoyLogoWithPadding id="footer2brand-desktop" logoColor={linkColor} />
      </LinkV1>
      <LinkV2 href={FOOTER_ABOUT_URL} target="__blank" {...getLinkStyles(linkColor, linkHoverColor)}>
        {about}
      </LinkV2>
    </StyledFlex>
  );
};
