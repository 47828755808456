import { Flex, styled, css, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledSuccessPageContainer = styled(Flex)`
  box-sizing: border-box;
  min-height: 100vh;
  align-items: stretch;
`;

//LEFT COLUMN
export const StyledActionButtonsContainer = styled(Flex)``;
const actionButtonsContainerStyles: StyleSystemProps = {
  justifyContent: 'center',
  backgroundColor: 'white',
  flex: 1,
  paddingBottom: pxToRem(64)
};

export const StyledActionButtonsWrapper = styled(Flex)``;
const actionButtonsWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  paddingX: { _: 6, md2: 0 },
  paddingTop: {
    _: 9,
    md2: 10
  },
  width: '100%',
  maxWidth: {
    _: '100%',
    sm: pxToRem(320)
  }
};

export const StyledFormContainer = styled(Flex)``;
const formContainerStyles: StyleSystemProps = {
  flexDirection: 'column',
  marginBottom: pxToRem(64)
};

//RIGHT COL

export const StyledInformationContainer = styled(Flex)``;
const informationContainerStyles: StyleSystemProps = {
  backgroundColor: 'mono1',
  flexDirection: 'column',
  width: { _: '100%', sm: '41.7%' },
  paddingLeft: { _: pxToRem(0), sm: pxToRem(64) },
  paddingTop: { _: pxToRem(40), sm: pxToRem(80) },
  paddingBottom: pxToRem(40)
};

export const StyledNextStepsButtonWrapper = styled(Flex)``;
const nextStepsButtonWrapperStyles: StyleSystemProps = {
  marginTop: { _: 5 },
  padding: { _: 5 },
  borderRadius: { _: 4 },
  border: '1px solid',
  borderColor: 'mono3',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer'
};

export const StyledInformationWrapper = styled(Flex)``;
export const informationWrapperStyles: StyleSystemProps = {
  width: { _: '100%', sm: pxToRem(320) }
};

export const BuyForMyselfWrapper = styled.div<{ isMobile?: boolean }>`
  ${props =>
    props.isMobile
      ? css`
          @media screen and (min-width: ${props => props.theme.breakpoints.sm2}) {
            display: none;
          }
        `
      : css`
          @media screen and (max-width: ${props => props.theme.breakpoints.sm2}) {
            display: none;
          }
        `}
`;

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';
export const StyledItemImage = styled.div<{ image: string | undefined }>`
  background-image: ${({ image }) => (image ? `url(${image})` : `url(${defaultImage})`)};
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: multiply;
  background-position: center center;
  color: gray;
  height: ${pxToRem(82)};
  width: ${pxToRem(82)};
  flex-shrink: 0;
`;

export const StyledItemImageLarge = styled(StyledItemImage)`
  height: ${pxToRem(160)};
  width: ${pxToRem(160)};
`;

export const styles = {
  informationContainerStyles,
  informationWrapperStyles,
  actionButtonsWrapperStyles,
  actionButtonsContainerStyles,
  formContainerStyles,
  nextStepsButtonWrapperStyles
};
