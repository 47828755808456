import { ApolloError } from '@apollo/client';
import {
  DesignLayoutType,
  EventDesignPurpose,
  EventPageFragment,
  EventPageType,
  useCheckCanAccessPageQuery,
  useGetRegistryDataLazyQuery,
  useGetRegistryPageLazyQuery,
  usePurchaseRegistryOrderMutation,
  useRegistryOrderByIdLazyQuery,
  useEventWebsiteGuestTelemetryQuery
} from '@graphql/generated';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { fontMapping } from '@shared/utils/fonts/fontMap';
import { useRoutePaths } from './GuestRegistry.routes';
import { useLocation } from '@react-router';
import * as H from 'history';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useEffect, useMemo, useState } from 'react';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useGuestRegistryState } from './state';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { Theme } from '@withjoy/joykit';
import { removeItemFromPurchaseClickHistory } from '../common/components/ShoppingCart/utils/trackPurchaseClicks';
import { GuestSiteEventDesign } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { useAppPage } from '@shared/utils/hooks/useAppPage';
import { RegistryOrderList, RegistryList } from '../common/state/RegistryProducts';
import { parseColorPalette } from '@shared/utils/websiteDesign';
import { withWindow } from '@shared/utils/withWindow';
import { useGenerateGuestSiteLoadTelemData } from '@apps/guest/routes/GuestSite/GuestSite.utils';
import { useLayoutAndThemeOverride } from '@apps/common/hooks/useLayoutAndThemeOverride';

import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { shouldUseEventDesignDraft } from './GuestRegistry.utils';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export type GuestRegistryState = Readonly<{
  eventDate?: string;
  eventPhoto?: string;
  firebaseId?: string;
  isAdmin: boolean;
  pageNote?: string | null;
  registry: RegistryList;
  registryCurrencyCode?: string;
  orders: RegistryOrderList;
  registryAdminPhoto?: string;
  registryPhoto?: string;
  eventId?: string;
  primaryTextColor?: string;
  font?: string;
  shippingAddress: ShippingAddressFragment | null;
  isRegistryPageDisabled?: boolean;
  isRegistryPagePasswordProtected?: boolean;
  pages?: ReadonlyArray<EventPageFragment>;
  layout?: DesignLayoutType;
  eventDesign?: GuestSiteEventDesign;
}>;

interface GuestRegistryProps
  extends Readonly<{
    loading: boolean;
    error?: ApolloError;
    registry: GuestRegistryState;
    routes: ReturnType<typeof useRoutePaths>;
    location: H.Location<H.LocationState>;
    canAccessPage?: boolean;
    eventId?: string;
    theme?: Theme;
  }> {}

export const useGuestRegistryController = (eventHandle: string): GuestRegistryProps => {
  const { role, hasIdentifiedUserOnce } = useEventUserRole();
  const { updateDataProvider, registry, orders } = useGuestRegistryState();
  const addAppPage = useAppPage();
  const telemetry = useGuestSiteTelemetry();
  const { orderId: orderIdQueryParams, action: orderAction } = useQueryParams();

  const isHideGetTheAppPageExperimentEnabled = useFeatureValue('hideGetTheAppPageExperiment').value === 'treatment';

  const [hasFiredGuestLoadTelem, setHasFiredGuestLoadTelem] = useState(false);

  const { data: activeSessionData, loading: activeSessionLoading, error: activeSessionError } = useCheckCanAccessPageQuery({
    batchMode: 'fast',
    variables: { eventHandle, type: EventPageType.registry }
  });
  const eventId = activeSessionData?.eventByName?.id;
  const firebaseId = activeSessionData?.eventByName?.firebaseId;

  const [getRegistryData, { loading, data, error }] = useGetRegistryDataLazyQuery({
    batchMode: 'fast',
    variables: { eventHandle, withGuestSiteData: true, eventDesignPurpose: shouldUseEventDesignDraft() ? EventDesignPurpose.draft : EventDesignPurpose.live }
  });
  const [getRegistryPageData, { data: pageData, loading: pageLoading, error: pageError }] = useGetRegistryPageLazyQuery({
    batchMode: 'fast',
    variables: { eventId: eventId || '' }
  });
  const { getEventDesignWithThemeAndLayout, loadingThemeData, font: selectedFont } = useLayoutAndThemeOverride({
    ssr: false
  });

  const routes = useRoutePaths();
  const location = useLocation();

  const canAccessPage = activeSessionData?.eventByName?.activeSession?.canAccessPage && !data && !pageData;
  const registryPage = activeSessionData?.eventByName?.pageInfo;

  useEffect(() => {
    if (canAccessPage) {
      getRegistryPageData();
      getRegistryData();
    }
  }, [getRegistryPageData, getRegistryData, canAccessPage]);

  const pages = useMemo(() => (isHideGetTheAppPageExperimentEnabled ? data?.eventByName?.pages : addAppPage(eventHandle, data?.eventByName)), [
    addAppPage,
    data?.eventByName,
    eventHandle,
    isHideGetTheAppPageExperimentEnabled
  ]);

  const info = data?.eventByName?.info;
  const eventDate = info?.finalizedDate?.milliseconds;
  let dateWithTimezoneFormatting;
  if (eventDate) {
    dateWithTimezoneFormatting = utcToZonedTime(eventDate, info?.finalizedDate?.timezone || DEFAULT_TIMEZONE);
  }
  const primaryTextColor = parseColorPalette(data?.eventByName?.eventDesign?.colorPalette || []).primaryTextColor?.color.hex;
  const font = typeof selectedFont === 'string' ? selectedFont : data?.eventByName?.eventDesign?.font?.fontFamily;
  const registryShippingAddress = data?.eventByName?.registry?.shippingAddress || null;

  let mappedFont = font;
  if (font && mappedFont) {
    if (fontMapping[font]) {
      mappedFont = fontMapping[font];
    }
  }

  useEffect(() => {
    // Check if window is defined to ensure code runs only in the client side
    withWindow(() => {
      // Dynamically import the webfontloader library
      import('webfontloader').then(WebFont => {
        WebFont?.load({
          google: {
            families: [mappedFont || '']
          }
        });
      });
    });
  }, [font, mappedFont]);

  const isAdmin = role === 'superAdmin' || role === 'admin' || role === 'owner';
  const registryPageWithGuestInfo = data?.eventByName?.pages.find(item => item.type === 'registry');

  let formattedDate;
  if (dateWithTimezoneFormatting) {
    formattedDate = format(dateWithTimezoneFormatting, 'MMMM d, yyyy');
  }

  const photo = data?.eventByName?.welcomePage[0]?.photo || registryPageWithGuestInfo?.photo;
  const eventPhoto = photo?.url || '';

  const eventDesign = useMemo(() => {
    return data?.eventByName?.eventDesign ? getEventDesignWithThemeAndLayout(data?.eventByName?.eventDesign) : data?.eventByName?.eventDesign;
  }, [data, getEventDesignWithThemeAndLayout]);

  const guestRegistry = {
    coupleDisplayName: info?.eventDisplayName,
    eventDate: formattedDate,
    eventPhoto,
    firebaseId,
    eventId,
    primaryTextColor,
    isAdmin,
    pageNote: registryPageWithGuestInfo?.subTitle,
    registry,
    registryCurrencyCode: data?.eventByName?.registry.currency?.code,
    orders,
    registryAdminPhoto: registryPageWithGuestInfo?.photo?.url,
    font: mappedFont,
    shippingAddress: registryShippingAddress,
    isRegistryPageDisabled: registryPage?.disabled,
    isRegistryPagePasswordProtected: registryPage?.private,
    pages,
    layout: eventDesign?.websiteLayout.layoutType,
    eventDesign
  };

  const getTypeFromOrderAction = (orderAction: string) => {
    switch (orderAction) {
      case 'undo':
        return 'undo';
      case 'purchase':
        return 'initial';
      case 'confirm':
        return 'purchased';
      default:
        return '';
    }
  };

  const [purchaseRegistryOrder] = usePurchaseRegistryOrderMutation();

  const [getRegistryOrderData] = useRegistryOrderByIdLazyQuery({
    variables: {
      id: orderIdQueryParams as string
    },
    batchMode: 'fast',
    onCompleted: data => {
      const registryOrder = data.registryOrderById;

      if (registryOrder) {
        const { lineItems, name, email } = registryOrder;
        const donationFundPlatformType = registryOrder?.lineItems?.[0]?.lineItemData?.donationFundPlatformType || null;
        const registryItem = lineItems[0];
        const orderType = getTypeFromOrderAction(orderAction as string);
        if (orderType === 'purchased') {
          purchaseRegistryOrder({ variables: { id: registryOrder.id } });
          const productId = registryItem.registryItem?.id;
          if (productId) {
            removeItemFromPurchaseClickHistory({ reservedOrderId: registryOrder.id, productId });
          }
        }
        updateDataProvider({
          type: orderType,
          productId: registryItem.registryItem?.id,
          order: {
            orderId: orderIdQueryParams as string,
            donationFundPlatformType: donationFundPlatformType!
          },
          guest: {
            name,
            email
          },
          quantity: Math.max(registryItem.quantity ? registryItem.quantity / 100 : 0, 0)
        });
      } else {
        updateDataProvider({
          type: 'error',
          order: null,
          guest: null,
          quantity: 0
        });
      }
    },
    onError: () => {
      updateDataProvider({
        type: 'error',
        order: null,
        guest: null,
        quantity: 0
      });
    }
  });

  useEffect(() => {
    if (orderIdQueryParams) {
      getRegistryOrderData();
    }
  }, [orderIdQueryParams, getRegistryOrderData]);

  const { data: guestSiteLoadTelemData, loading: guestSiteLoadTelemDataLoading } = useEventWebsiteGuestTelemetryQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: EventDesignPurpose.live,
      isAdminDashboard: isAdmin
    },
    batchMode: 'fast',
    skip: !hasIdentifiedUserOnce
  });

  const extraInfo = useGenerateGuestSiteLoadTelemData(guestSiteLoadTelemData?.eventByName, role);

  useEffect(() => {
    if (!guestSiteLoadTelemDataLoading && guestSiteLoadTelemData && !hasFiredGuestLoadTelem) {
      telemetry.guestSiteLoaded(extraInfo);
      setHasFiredGuestLoadTelem(true);
    }
  }, [guestSiteLoadTelemDataLoading, guestSiteLoadTelemData, setHasFiredGuestLoadTelem, extraInfo, hasFiredGuestLoadTelem, telemetry]);

  return {
    error: error || pageError || activeSessionError,
    loading: loading || pageLoading || activeSessionLoading || loadingThemeData || (!data && !!canAccessPage),
    registry: guestRegistry,
    routes,
    location,
    canAccessPage: activeSessionData?.eventByName?.activeSession?.canAccessPage,
    eventId: activeSessionData?.eventByName?.id
  };
};
