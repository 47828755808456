import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Box, ButtonV2, IconV2, LinkV2, TextV2Props, useDisclosure } from '@withjoy/joykit';
import { NoteText, StyledLinkify, NoteTextWrapper } from './CoupleHeader.styles';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import globalWindow from '@shared/core/globals';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { PageNoteDialog } from '../PageNoteDialog';
import { Edit, Note } from '@withjoy/joykit/icons';
import { IconCard } from '@apps/registry/common/components/EventPreview/EventPreview';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { OverrideTypography } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.types';
import { Category } from '@graphql/generated';
import { body3ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';
import { getButtonPropsBasedOnBackground, getHoverColor } from '@apps/guest/packages/layout-engine/layouts/layout.utils';

type PageNoteTextProps = {
  noteText: Maybe<string>;
  eventHandle: string;
  onClickReadMore?: () => void;
  onEditPageNote?: () => void;
  showEdit: boolean;
  showEmptyCard?: boolean;
  isCustomButtonStylesEnabled: boolean;
};

export const PageNoteText: React.FC<PageNoteTextProps & TextV2Props> = ({
  noteText = '',
  onClickReadMore,
  onEditPageNote,
  eventHandle,
  showEdit = false,
  showEmptyCard = false,
  isCustomButtonStylesEnabled: isCustomButtonColorEnabled,
  ...textProps
}) => {
  const noteRef = useRef<HTMLDivElement>(null);
  const [hasMore, setHasMore] = useState(false);
  const [isExpanded, setIsExpand] = useState(false);

  const { isOpen: isPageNoteDialogOpen, onOpen: openPageNoteDialog, onClose: closePageNoteDialog } = useDisclosure();

  const [, setHasMountedOnce] = useState(false);

  const { getCoupleHeaderTranslations } = useRegistryGuestTranslations();
  const { readMore, readLess, noteButton, addNoteButton } = getCoupleHeaderTranslations();
  const { backgroundColor, textColor } = useColorPaletteProvider();
  const customEditNoteButtonProps = useMemo(() => (backgroundColor && isCustomButtonColorEnabled ? { ...getButtonPropsBasedOnBackground(backgroundColor) } : {}), [
    backgroundColor,
    isCustomButtonColorEnabled
  ]);
  const hoverColor = useMemo(() => (textColor && backgroundColor ? getHoverColor(textColor) : undefined), [textColor, backgroundColor]);
  const expandText = isExpanded ? readLess : readMore;
  const emptyNote = !noteText?.length;

  const isMobile = useMediaQuery(theme => theme.mediaQueries.down({ breakpointAlias: 'sm4' }));

  const noteTextTypographyOverride: OverrideTypography = useMemo(() => [{ variant: isMobile ? 'body1' : 'body3', category: Category.PARAGRAPH }], [isMobile]);

  const isMounted = useIsMounted();
  useEffect(() => {
    let timeout: ReturnType<typeof globalWindow.setTimeout>;
    if (isMounted) {
      timeout = globalWindow.setTimeout(() => {
        setHasMountedOnce(true);
      }, 1000);
    }
    return () => {
      globalWindow.clearTimeout(timeout);
    };
  }, [isMounted]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useIsomorphicLayoutEffect(() => {
    if (noteRef.current && noteRef.current.offsetHeight < noteRef.current.scrollHeight) {
      setHasMore(true);
    }
  });

  const onClickEdit = useCallback(() => {
    openPageNoteDialog();
  }, [openPageNoteDialog]);

  return (
    <>
      {emptyNote && showEmptyCard ? (
        <IconCard text="Write a welcome note" icon={<Note />} onClick={onClickEdit} />
      ) : (
        <>
          <Box>
            <GuestSiteTypographyOverride override={noteTextTypographyOverride}>
              <NoteTextWrapper isOpen={isExpanded} ref={noteRef}>
                <NoteText {...textProps} typographyVariant={isMobile ? 'body1' : 'body3'} isOpen={isExpanded}>
                  <StyledLinkify primaryTextColor={textColor} hoverColor={hoverColor}>
                    {noteText}
                  </StyledLinkify>
                </NoteText>
              </NoteTextWrapper>
            </GuestSiteTypographyOverride>
            {hasMore && (
              <Box marginTop={7} textAlign="left">
                <GuestSiteTypographyOverride override={body3ToParagraphOverride}>
                  <LinkV2
                    typographyVariant="body3"
                    color="mono14"
                    textDecoration="underline"
                    onClick={() => {
                      setIsExpand(prev => !prev);
                      onClickReadMore?.();
                    }}
                  >
                    {expandText}
                  </LinkV2>
                </GuestSiteTypographyOverride>
              </Box>
            )}
          </Box>
          {showEdit && (
            <ButtonV2 typographyVariant="button2" intent="neutral" variant="outline" onClick={onClickEdit} marginTop={7} borderRadius="1.5rem" {...customEditNoteButtonProps}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box marginRight={3} display="flex" justifyContent="center" alignItems="center">
                  <IconV2 size="sm">
                    <Edit />
                  </IconV2>
                </Box>
                {!emptyNote ? noteButton : addNoteButton}
              </Box>
            </ButtonV2>
          )}
        </>
      )}
      <PageNoteDialog onEditPageNote={onEditPageNote} isOpen={isPageNoteDialogOpen} onClose={closePageNoteDialog} pageNote={noteText} isAdmin={true} eventHandle={eventHandle} />
    </>
  );
};
