import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useTranslation } from '@shared/core/i18n';
import { computeRepeatableNumberFromUUID } from '@shared/utils/uuid';

export const useYourRegistryTranslations = () => {
  const { t } = useTranslation('yourRegistry');

  const registrySectionTranslations = t('registrySection');
  const getRegistrySectionTranslations = () => {
    return {
      title: registrySectionTranslations.title(),
      subtitle: registrySectionTranslations.subtitle(),
      dndLabel: registrySectionTranslations.dndLabel(),
      reorder: registrySectionTranslations.reorder(),
      finish: registrySectionTranslations.finish(),
      emptyRegistries: registrySectionTranslations.emptyRegistries(),
      goAheadAnd: registrySectionTranslations.goAheadAnd(),
      createOne: registrySectionTranslations.createOne(),
      registrySyncing: registrySectionTranslations.registrySyncing(),
      registrySynced: registrySectionTranslations.registrySynced()
    };
  };

  const validationTranslations = t('validation');
  const getValidationTranslations = () => {
    return {
      invalidDomain: validationTranslations.invalidDomain(),
      required: validationTranslations.required()
    };
  };

  const errorDialofTranslations = t('errorDialog');
  const getErrorDialogTranslations = () => {
    return {
      titleText: errorDialofTranslations.titleText(),
      messageTextStart: errorDialofTranslations.messageTextStart(),
      messageTextEnd: errorDialofTranslations.messageTextEnd(),
      actionText: errorDialofTranslations.actionText()
    };
  };

  const registryProductRowTranslations = t('registryProductRow');
  const getRegistryProductRowTranslations = (product?: CookedProduct) => {
    const { rewardPhrasesCompleted } = registryProductRowTranslations;
    const repeatableNumber = computeRepeatableNumberFromUUID(product?.id);
    const rewardPhraseIndex = repeatableNumber % Object.keys(rewardPhrasesCompleted).length;
    return {
      giftPurchased:
        product && product.purchased > 1 ? registryProductRowTranslations.giftsPurchased({ amount: product.purchased }) : registryProductRowTranslations.giftPurchased(),
      giftsRequested: registryProductRowTranslations.giftsRequested({ amount: product?.requested || 0 }),
      giftsStillNeeded: registryProductRowTranslations.giftStillNeeded({ amount: product?.stillNeeded || 0 }),
      completedLabel: rewardPhrasesCompleted[rewardPhraseIndex]?.(),
      favorite: registryProductRowTranslations.favorite(),
      itemStillNeeded: registryProductRowTranslations.itemStillNeeded(),
      itemsStillNeeded: registryProductRowTranslations.itemsStillNeeded(),
      reorderGifts: registryProductRowTranslations.reorderGifts(),
      doneReordering: registryProductRowTranslations.doneReordering(),
      reserved: registryProductRowTranslations.reserved(),
      purchased: registryProductRowTranslations.purchased(),
      reservedByYou: registryProductRowTranslations.reservedByYou(),
      purchasedByYou: registryProductRowTranslations.purchasedByYou(),
      reservedByHeader: registryProductRowTranslations.reservedByColumnHeader(),
      purchasedByHeader: registryProductRowTranslations.purchasedByColumnHeader(),
      purchasedByOverflow: (initialEntry: string, remaining: number) => registryProductRowTranslations.purchasedByOverflow({ initialEntry, remaining }),
      removingGift: registryProductRowTranslations.removingGift(),
      categoryCashFundText: registryProductRowTranslations.categoryCashFundText(),
      categoryCharityFundText: registryProductRowTranslations.categoryCharityFundText(),
      reorderMessage: registryProductRowTranslations.reorderMessage(),
      giftedSoFarText: registryProductRowTranslations.giftedSoFarText()
    };
  };

  const amazonRegistryTranslations = t('amazonRegistry');
  const getAmazonRegistryTranslations = () => {
    return {
      heading: amazonRegistryTranslations.heading(),
      lastSync: amazonRegistryTranslations.lastSync(),
      manageReg: amazonRegistryTranslations.manageReg(),
      inProgressSync: amazonRegistryTranslations.inProgressSync(),
      syncing: amazonRegistryTranslations.syncing()
    };
  };

  const registryTranslations = t('registry');
  const getRegistryTranslations = () => {
    return {
      heading: registryTranslations.heading()
    };
  };

  const emptyFilterTranslations = t('emptyFilter');
  const getEmptyFilterTranslations = () => {
    return {
      stillNeededTitle: emptyFilterTranslations.stillNeededTitle(),
      stillNeededSubtitle: emptyFilterTranslations.stillNeededSubtitle(),
      purchasedTitle: emptyFilterTranslations.purchasedTitle(),
      purchasedSubtitle: emptyFilterTranslations.purchasedSubtitle(),
      favoriteTitle: emptyFilterTranslations.favoriteTitle(),
      favoriteSubtitle: emptyFilterTranslations.favoriteSubtitle(),
      defaultTitle: emptyFilterTranslations.defaultTitle(),
      defaultSubTitle: emptyFilterTranslations.defaultSubTitle(),
      noSyncableRegistryTitle: emptyFilterTranslations.noSyncableRegistryTitle(),
      noSyncableRegistrySubtitle: emptyFilterTranslations.noSyncableRegistrySubtitle()
    };
  };

  const registryStatsTranslations = t('registryStats');
  const getRegistryStatsTranslations = () => ({
    giftsAdded: registryStatsTranslations.giftsAdded(),
    reserved: registryStatsTranslations.reserved(),
    stillNeeded: registryStatsTranslations.stillNeeded()
  });

  const filterSectionRegistryTranslations = t('filterSectionRegistry');
  const getFilterSectionRegistryTranslations = () => {
    return {
      everything: filterSectionRegistryTranslations.everything(),
      stillNeeded: filterSectionRegistryTranslations.stillNeeded(),
      purchased: filterSectionRegistryTranslations.purchased()
    };
  };

  const giftTypeOptionsButtonsTranslations = t('giftTypeOptionsButtons');

  const getGiftTypeOptionsButtons = () => {
    return {
      product: giftTypeOptionsButtonsTranslations.product(),
      cashFund: giftTypeOptionsButtonsTranslations.cashFund(),
      charity: giftTypeOptionsButtonsTranslations.charity()
    };
  };

  const productDetailsDialogTranslations = t('productDialog');
  const getProductDetailsDialogTranslations = () => {
    return {
      giftAddedFrom: productDetailsDialogTranslations.giftAddedFrom,
      alertEditRegistry: productDetailsDialogTranslations.alertEditRegistry(),
      removedCustomItem: productDetailsDialogTranslations.removedCustomItem(),
      linkRegistry: productDetailsDialogTranslations.linkRegistry(),
      giftRemoved: productDetailsDialogTranslations.giftRemoved(),
      giftEditDetails: productDetailsDialogTranslations.giftEditDetails()
    };
  };

  const cancelReservationDialogTranslations = t('cancelReservationDialog');
  const getCancelReservationDialogTranslations = () => {
    return {
      cancelReservationHeader: cancelReservationDialogTranslations.cancelReservationHeader(),
      cancelReservationBody: cancelReservationDialogTranslations.cancelReservationBody(),
      cancelReservationCancelButton: cancelReservationDialogTranslations.cancelReservationCancelButton(),
      cancelReservationConfirmButton: cancelReservationDialogTranslations.cancelReservationConfirmButton(),
      cancelReservationButtonText: cancelReservationDialogTranslations.cancelReservationButtonText()
    };
  };

  const previewSendEmailReminderDialogTranslations = t('previewSendEmailReminderDialog');
  const getPreviewSendEmailReminderDialogTranslations = () => {
    return {
      header: previewSendEmailReminderDialogTranslations.header(),
      body: previewSendEmailReminderDialogTranslations.body(),
      sampleText: previewSendEmailReminderDialogTranslations.sampleText(),
      cancelButton: previewSendEmailReminderDialogTranslations.cancelButton(),
      confirmButton: previewSendEmailReminderDialogTranslations.confirmButton(),
      prompt: previewSendEmailReminderDialogTranslations.prompt(),
      buttonText: previewSendEmailReminderDialogTranslations.buttonText(),
      successText: previewSendEmailReminderDialogTranslations.successText()
    };
  };

  const downloadDialogDialogTranslations = t('downloadDialog');
  const getDownloadDialogTranslations = () => {
    return {
      downloadDialogEyebrowText: downloadDialogDialogTranslations.downloadDialogEyebrowText(),
      downloadDialogHeaderText: downloadDialogDialogTranslations.downloadDialogHeaderText(),
      downloadDialogBodyText: downloadDialogDialogTranslations.downloadDialogBodyText()
    };
  };

  return {
    getRegistrySectionTranslations,
    getValidationTranslations,
    getErrorDialogTranslations,
    getRegistryProductRowTranslations,
    getRegistryStatsTranslations,
    getAmazonRegistryTranslations,
    getRegistryTranslations,
    getEmptyFilterTranslations,
    getFilterSectionRegistryTranslations,
    getProductDetailsDialogTranslations,
    getGiftTypeOptionsButtons,
    getCancelReservationDialogTranslations,
    getPreviewSendEmailReminderDialogTranslations,
    getDownloadDialogTranslations
  };
};
